<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <p>الشركات</p>
        <div class="form-group">
          <label for="">اسم الشركة</label>
          <input type="text"
            class="form-control" v-model="title">
        </div>
        <div class="form-group">
            <label for=""> المدينة</label>
            <select class="form-control" v-model="city_id" name="" id="">
                <option :value="null">-- اختر --</option>
                <option v-for="city in settings.cities" :key="city.id" :value="city.id">{{ city.title }}</option>
            </select>
        </div>
        <div class="form-group">
          <label for="">الهاتف</label>
          <input type="text"
            class="form-control" v-model="phone">
        </div>
        <div class="col-12 text-center g" @click="addItem()">
            <button class="btn btn-primary">
                <i class="fa fa-save"></i>
                اضافة
            </button>
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <hr>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الشركة
                    </th>
                    <th>
                        الهاتف
                    </th>
                    <th>
                        المدينة
                    </th>
                    <th>
                        حذف
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in arr" :key="item.id">
                        <td>
                            <input type="text" style="border:none;" v-model="item.title" @change="editItem(item._id, item)">
                        </td>
                        <td>
                            <input type="text" style="border:none;" v-model="item.phone" @change="editItem(item._id, item)">
                        </td>
                        <td>
                            <select class="form-control" v-model="item.city_id" name="" id="" @change="editItem(item._id, item)">
                                <option :value="null">-- اختر --</option>
                                <option v-for="city in settings.cities" :key="city.id" :value="city.id">{{ city.title }}</option>
                            </select>
                        </td>
                        <td>
                            <button class="btn btn-sm text-danger fa fa-trash" @click="deleteItem(item._id)"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            arr: [],
            error: "",
            settings: {},
            title: "",
            phone: "",
            city_id: null
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/companies/all', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.arr = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/admin/general/settings', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    if(r.response.send_withdraw != 'yes' && r.response.send_withdraw != 'true'){
                        r.response.send_withdraw = false;
                    }
                    g.settings = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        editItem(id, item){
            var g = this;
            $.post(api + '/admin/companies/edit', {
                    jwt: this.jwt,
                    id: id,
                    title: item.title,
                    city_id: item.city_id,
                    phone: item.phone
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
        },
        addItem(){
            var g =this;
            if(g.title && g.title != "" && g.city_id){
                $.post(api + '/admin/companies/add', {
                    jwt: this.jwt,
                    title: g.title,
                    city_id: g.city_id,
                    phone: g.phone
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        },
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من حذف الشركة نهائياً؟")){
                $.post(api + '/admin/companies/delete', {
                    jwt: this.jwt,
                    id: id
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        }
    },
}
</script>

<style>

</style>